import type { AppProps } from "next/app"
import { GlobalStyles, AuthProvider } from "../utils"
import "../utils/css-imports"
import React, { useEffect, useState } from "react"
import Head from "next/head"
import { CloseCircleFilled } from "@ant-design/icons"
import { message } from "antd"
import { userPool } from "@project/shared"
import { t } from "i18next"
import * as Sentry from "@sentry/node"
import { QueryClient, QueryClientProvider } from "react-query"
import { BusinessType } from "../utils/BusinessType"

export const queryClient = new QueryClient({ defaultOptions: {} })

export default function MyApp({ Component, pageProps }: AppProps) {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)

  const checkLoggedInUser = (payload: any) => {
    if (
      payload["cognito:groups"]?.[0] === BusinessType.FACILITY_ADMIN ||
      payload["cognito:groups"]?.[0] === BusinessType.FACILITY_STAFF
    ) {
      setUser(payload)
    } else {
      setUser(null)
      message.error({
        key: "01",
        icon: <CloseCircleFilled onClick={() => message.destroy("01")} />,
        content: t("Unauthorized user"),
      })
    }
  }

  const initialLoad = () => {
    try {
      const user = userPool.getCurrentUser()
      if (user) {
        user.getSession(
          (
            err: any,
            session: { isValid: () => any; getIdToken: () => any }
          ) => {
            if (!err && session.isValid()) {
              const idTokenPayload = session.getIdToken().payload
              checkLoggedInUser(idTokenPayload)
            }
          }
        )
      }
      setLoading(false)
    } catch (error) {
      setUser(null)
      setLoading(false)
      Sentry.captureException(error)
      message.error({
        key: "02",
        content: t("An error has occurred. Please try again later."),
        icon: <CloseCircleFilled onClick={() => message.destroy("02")} />,
      })
    }
  }

  useEffect(() => {
    initialLoad()
  }, [])

  return (
    <React.Fragment>
      <Head>
        <link
          rel={"icon"}
          type={"image/svg+xml"}
          href={"/assets/icons/logo.svg"}
        />
        <title>{"管理サイト"}</title>
      </Head>
      <GlobalStyles />
      <QueryClientProvider client={queryClient}>
        <AuthProvider loading={loading} user={user} setUser={setUser}>
          <Component {...pageProps} />
        </AuthProvider>
      </QueryClientProvider>
    </React.Fragment>
  )
}
